@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Lato";
    src: url("./fonts/lato/Lato-Light.woff2") format("woff2"),
      url("./fonts/lato/Lato-Light.woff") format("woff");

    font-weight: 300;
  }

  @font-face {
    font-family: "Lato";
    src: url("./fonts/lato/Lato-Regular.woff2") format("woff2"),
      url("./fonts/lato/Lato-Regular.woff") format("woff");

    font-weight: 400;
  }

  @font-face {
    font-family: "Lato";
    src: url("./fonts/lato/Lato-Medium.woff2") format("woff2"),
      url("./fonts/lato/Lato-Medium.woff") format("woff");

    font-weight: 500;
  }

  @font-face {
    font-family: "Lato";
    src: url("./fonts/lato/Lato-Bold.woff2") format("woff2"),
      url("./fonts/lato/Lato-Bold.woff") format("woff");

    font-weight: 700;
  }
}
